import React from "react";
import { css } from "@emotion/react";
import Icon from "./Icon";
import { Div } from "./Markup";

const Social = () => {
  return (
    <Div
      flex
      ai="center"
      css={css`
        @media (max-width: 767px) and (max-height: 600px) {
          display: none;
        }
        @media (max-width: 991px) {
          justify-content: center;
        }
        svg {
          margin-right: 8px;
          margin-left: 8px;

          @media (min-width: 992px) {
            margin-left: 24px;
            margin-right: 0px;
          }
        }
      `}
    >
      <a
        href="https://www.facebook.com/hawlikgerginskiarchitekten/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="facebook" size="sm" />
      </a>
      <a
        href="https://www.instagram.com/hawlik_gerginski_architekten/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="instagram" size="sm" />
      </a>
      <a
        href="https://www.linkedin.com/company/hawlik-gerginski-architekten-zt-gmbh/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="linkedin" size="sm" />
      </a>
    </Div>
  );
};

export default Social;
