import { css } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { Div } from "./Markup";
import { gsap } from "gsap";
import Logo from "@components/Logo";

const Loader = () => {
  const loaderScreenRef = useRef(null);
  const loaderProgressRef = useRef(null);

  const hideLoader = () => {
    gsap
      .timeline()
      .to(loaderScreenRef.current, {
        height: "0px",
        duration: 0.55,
        delay: 3,
        ease: "power2.easeOut",
      })
      .to(
        loaderProgressRef.current,
        {
          width: "100%",
          duration: 3,
        },
        "-=3"
      )
      .to(loaderScreenRef.current, {
        opacity: 0,
        duration: 0.15,
      });
  };

  // loader doesn't need to wait
  useEffect(() => {
    hideLoader();
  }, []);

  return (
    <Div
      ref={loaderScreenRef}
      flex
      ai="center"
      jc="center"
      css={css`
        position: fixed;
        top: 0px;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 2000;
        background: white;
        opacity: 1;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          h6 {
            margin-bottom: 16px;
            text-align: center;
          }

          img {
            margin-bottom: 32px;
          }
        `}
      >
        <Logo />
        <div
          css={css`
            display: block;
            width: 100%;
            height: 1px;
            position: relative;

            text-align: center;
          `}
        >
          <span
            ref={loaderProgressRef}
            css={css`
              position: absolute;
              height: 100%;
              width: 0%;
              background: black;
              left: 0;
              top: 0;
            `}
          />
        </div>
      </div>
    </Div>
  );
};

export default Loader;
