export const BLACK = "#333333";
export const LIGHTGREY = "#aaaaaa";
export const GREY = "#7F7F7F";
export const WHITE = "#ffffff";

// remove inactive projects from grid
// expertise should follow the same layout - tabs and more similar to projects
// tabs for office / team in about us page
// max 8 images per project and a slideshow - YES
// add project partner to data model - YES
// leistungen - stays between images
// projet partners below description - YES
// remove left padding of map - YES
// remove link to google map - YES
// redeuce padding between sections
