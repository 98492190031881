import React from "react";
import { Global, css } from "@emotion/react";

import NoahRegularWoff from "./noah-regular-webfont.woff";
import NoahRegularWoff2 from "./noah-regular-webfont.woff2";
import NoahHeavyWoff from "./noah-heavy-webfont.woff";
import NoahHeavyWoff2 from "./noah-heavy-webfont.woff2";
import LucidaSans from "./lucidasans.woff";
import LucidaSansBold from "./lucidasansbold.woff";

// import OpenSansSemiBoldWoff from './OpenSans-SemiBold.woff';
// import OpenSansSemiBoldWoff2 from './OpenSans-SemiBoldwoff2';

const GlobalFonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "noah-heavy";
        src: url(${NoahHeavyWoff2}) format("woff2"),
          url(${NoahHeavyWoff}) format("woff");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "noah-regular";
        src: url(${NoahRegularWoff2}) format("woff2"),
          url(${NoahRegularWoff}) format("woff");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "lucida-sans";
        src: url(${LucidaSans}) format("woff");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "lucida-sans-bold";
        src: url(${LucidaSansBold}) format("woff");
        font-weight: normal;
        font-style: normal;
      }

      html,
      body {
        margin: 0;
        line-height: 1.5em;
        font-family: "lucida-sans";
        font-size: 18px;
        line-height: 1.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      a,
      a:visited {
        color: inherit;
        text-decoration: none;
      }
    `}
  />
);

export default GlobalFonts;
